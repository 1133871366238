import { useState } from "react";
import { useAuth } from "lib/auth";
import SiteMaps from "pages/Reports/Map";
import Controls from "components/Controls";
import { useNavigate } from "react-router-dom";

const ClientDashboard = () => {
  const [modal, setModal] = useState(true);
  const [privacyNotice, setPrivacyNotice] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const { user } = auth;

  const organisationIds = user?.data?.userOrganisations.map((org) => {
    return org.organisationId;
  });

  const toggleModal = (rowData: any) => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const togglePrivacyNotice = (rowData: any) => {
    setPrivacyNotice(!privacyNotice);
  };

  const closePrivacyNotice = () => {
    setPrivacyNotice(false);
  };

  const navigateToListView = () => {
    navigate(`reports/list`, {
      state: {
        clientId: null,
        clientName: null,
        clientOrgIds: organisationIds,
        organisationId: "all",
        orgName: "All Businesses",
      },
    });
  };

  return (
    <>
      {privacyNotice && (
        <div className="modal privacyNotice">
          <div className="overlay" />
          <div className="modal-content">
            <h2>Privacy Notice</h2>
            <p>
              We value your privacy. By continuing to use this site, you agree
              to our collection and use of your information in line with our
              Privacy Policy. This includes data needed for your login, account
              management, and to improve your experience.
            </p>
            <p>If you have any questions, please contact us.</p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={closePrivacyNotice}
                text="Accept and Continue"
              />
            </div>
          </div>
        </div>
      )}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" />
          <div className="modal-content">
            <h2>Welcome to your map</h2>
            <p>
              Welcome to your results map! Here you see all the workplaces we
              have covered for you. Click on an individual pin to see more on
              that workplace.
            </p>
            <p>
              If you are a Chinese-based business, please click the LIST VIEW
              button below, to access your results.
            </p>
            <br />
            <div className="form-buttons custom-floatRight">
              <Controls.Button
                className="button blue"
                onClick={navigateToListView}
                text="List View"
              />
              <Controls.Button
                text="Close"
                className="cancel-button"
                variant="outlined"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>
      )}
      {google && (
        <SiteMaps
          clientOrgIds={organisationIds}
          organisationId={"all"}
          orgName={"All Businesses"}
        />
      )}
    </>
  );
};

export default ClientDashboard;
